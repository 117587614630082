<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="back">直播课程审核</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 class="flexcb" style="padding-bottom: 20px">
              <span>直播课程基本信息</span>
            </h3>
            <div>
              <el-form ref="ruleForm" label-width="10rem">
                <el-form-item label="直播课程名称：" class="form-inline">
                  <p>{{ ruleForm.liveCourseName }}</p>
                </el-form-item>
                <el-form-item label="直播课程图片：" prop="logoUrl">
                  <div class="img-el-upload" style="width: 256px">
                    <el-image
                      :src="ruleForm.logoUrl || require('@/assets/develop.png')"
                      fit="contain"
                    ></el-image>
                  </div>
                </el-form-item>
                <el-form-item label="直播课程简介：" prop="courseProfile">
                  <p>{{ ruleForm.courseProfile || "无" }}</p>
                </el-form-item>
                <el-form-item label="关联班级：" prop="relationClass">
                  <p>{{ relationClass || "无" }}</p>
                </el-form-item>
              </el-form>
            </div>
            <el-divider></el-divider>
            <h3 style="padding-bottom: 20px">直播课节目录</h3>
            <!-- <div class="framePage-scroll">
            <div class="ovy-a">-->
            <el-table
              ref="multipleTable"
              :data="List"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="直播课节名称"
                align="center"
                :show-overflow-tooltip="true"
                prop="liveLessonName"
              />
              <el-table-column
                label="讲师"
                align="center"
                :show-overflow-tooltip="true"
                prop="teacherNames"
              />
              <el-table-column
                label="直播时间"
                align="center"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime }}~{{ scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="直播状态"
                align="center"
                :show-overflow-tooltip="true"
                prop="liveState"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("LIVE_STATE", scope.row.liveState || "--")
                  }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <el-divider></el-divider>
            <h3 style="padding: 20px 0">审批记录</h3>
            <!-- <div class="framePage-scroll">
            <div class="ovy-a">-->
            <el-table
              ref="multipleTable"
              :data="ApprovalRecordList"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="操作日期"
                align="center"
                :show-overflow-tooltip="true"
                prop="createTime"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作人"
                align="center"
                :show-overflow-tooltip="true"
                prop="fullname"
              />
              <el-table-column
                label="操作内容"
                align="center"
                :show-overflow-tooltip="true"
                prop="auditState"
              >
                <template slot-scope="scope">
                  <span>{{ getAuditName(scope.row.auditState) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="审批备注"
                align="center"
                :show-overflow-tooltip="true"
                prop="auditReason"
              />
              <Empty slot="empty" />
            </el-table>
            <!-- </div>
            </div>-->
            <!-- <h3 style="padding-bottom:20px;">审核备注</h3>
            <el-input type="textarea" :rows="4" placeholder="请输入审核理由" v-model="textarea"></el-input>-->
            <div class="btn-box">
              <el-button class="bgc-bv" @click="back">取 消</el-button>
              <el-button
                v-if="name == 'first'"
                @click="projectReject"
                class="bgc-bv"
                :disabled="startStudy"
                >驳 回</el-button
              >
              <el-button
                v-if="name != 'second'"
                class="bgc-bv"
                @click="projectPass"
                >通 过</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="审核事由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          type="textarea"
          maxlength="100"
          show-word-limit
          placeholder="请输入审核理由"
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "liveClassFestivalDetails",
  components: {},
  mixins: [List],
  data() {
    return {
      auditReason: "",
      liveCourseId: JSON.parse(this.$route.query.getClassInfo).liveCourseId,
      name: JSON.parse(this.$route.query.getClassInfo).name,
      relationClass: JSON.parse(this.$route.query.getClassInfo).relationClass,
      ruleForm: {},
      List: [],
      dialogVisible: false,
      ApprovalRecordList: [],
    };
  },
  mounted() {
    // this.init();
  },
  created() {
  },
  methods: {
    init() {
      this.getDatas();
      this.getDatasall();
      this.getInfo();
    },
    getAuditName(value) {
      let AuditNameList = [
        {
          value: "10",
          label: "课程创建",
        },
        {
          value: "20",
          label: "提交审核",
        },
        {
          value: "25",
          label: "撤消审核",
        },
        {
          value: "30",
          label: "审核通过",
        },
        {
          value: "40",
          label: "审核驳回",
        },
      ];
      for (let i in AuditNameList) {
        if (AuditNameList[i].value == value) {
          return AuditNameList[i].label;
        }
      }
    },
    //获取直播课程基本信息数据
    getInfo(liveCourseId) {
      this.$post(
        "/biz/live/course/getInfo",
        { liveCourseId: this.liveCourseId },
        3000,
        true,
        3
      )
        .then((ret) => {
          this.ruleForm = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    getDatas() {
      this.$post(
        "/auditLog/live-audit-log/queryLogInfoByType",
        {
          auditObjectId: this.liveCourseId,
        },
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.ApprovalRecordList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getDatasall() {
      this.$post(
        "/biz/live/lesson/queryLessonList",
        {
          liveCourseId: this.liveCourseId,
        },
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.List = ret.data.list || [];
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 审核通过
    projectPass() {
      const parmar = {
        liveCourseId: this.liveCourseId,
      };
      this.$post("/biz/live/course/auditPassed", parmar, 3000, true, 3)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "审核成功",
            });
            this.$router.push({
              path: "/web/liveBroadcastManagement/liveClassFestivalReview",
              query: {
                active: this.name ? this.name : "three",
                refresh:true
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    projectReject() {
      this.dialogVisible = true;
    },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        liveCourseId: this.liveCourseId,
        auditReason: this.auditReason,
      };
      this.$post("/biz/live/course/auditRefuse", parmar,3000,true,3)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            this.$router.push({
              path: "/web/liveBroadcastManagement/liveClassFestivalReview",
              query: {
                active: this.name ? this.name : "three",
                 refresh:true
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    back() {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassFestivalReview",
        query: {
          active: this.name ? this.name : "first",
        },
      });
    },
    // goHerf() {
    //   this.$router.push({
    //     path: "/web/liveBroadcastManagement/liveClassFestivalReview",
    //     query: {
    //       active: this.status ? this.status : "first"
    //       // name:
    //       //   this.status == "first"
    //       //     ? "first"
    //       //     : this.status == "second"
    //       //     ? "second"
    //       //     : "three"
    //     }
    //   });
    // }
  },
};
</script>
<style lang="less">
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
</style>
<style lang="less" scoped>
.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
</style>